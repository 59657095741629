import {inject, LogManager} from "aurelia-framework";
import {BindingSignaler} from "aurelia-templating-resources";
import * as _ from "lodash";

const logger = LogManager.getLogger('table');

@inject(BindingSignaler)
export class SelectService {
    selectedItems = [];
    multiSelect = true;

    onSelectionChange = (items) => {
    };

    constructor(signaler) {
        this.signaler = signaler;
    }

    /**
     * If item is selected at the moment
     */
    isSelected(item) {
        return this._findIndex(item) > -1;
    }

    /**
     * Select / unselect item
     */
    select(item, checked) {

        if (this.multiSelect) {

            if (checked) {
                if (!this.isSelected(item)) {
                    this.selectedItems.push(item);
                }
            } else {
                let index = this._findIndex(item);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }

        } else {
            if (checked) {
                this.selectedItems = [
                    item
                ];
            } else {
                this.selectedItems = [];
            }
        }

        this._triggerEvent();
    }

    /**
     * Unselect all
     */
    unselect() {
        this.selectedItems = [];

        this._triggerEvent();
    }

    _triggerEvent() {
        logger.debug('SelectService: Selection change', this.selectedItems);

        this.onSelectionChange(this.selectedItems);
        this.signaler.signal('sio-table-select-change');
    }

    _findIndex(item) {
        return _.findIndex(this.selectedItems, (e) => {
            return e.modelId === item.modelId && e.id === item.id;
        });
    }
}
