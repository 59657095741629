import {bindable, containerless, inject} from "aurelia-framework";
import {Client} from "../api/client";

import "./text-preview-wrapper.less";

@containerless()
@inject(Client)
export class TextPreviewWrapper
{
    @bindable field;
    @bindable value;

    preview = {};

    constructor(client)
    {
        this.client = client;
    }

    valueChanged() {
        if (!this.field) {
            return;
        }
        if (this.field.options && true === this.field.options.updatePreviewOnChange) {
            this.reloadPreview();
        }
    }

    bind() {
        this.reloadPreview();
    }

    reloadPreview() {
        this.preview = {};

        if (!this.field) {
            return;
        }

        if (this.field.options && this.field.options.preview) {
            this.preview = this.field.options.preview;
        }

        if (!this.field.previewEndPoint) {
            return;
        }

        this.client
            .post(
                this.field.previewEndPoint,
                {
                    object: {
                        id: this.field.formService.form._objectIdentifier,
                        modelId: this.field.formService.config.modelId
                    },
                    formId: this.field.formService.config.moduleId + '/' + this.field.formService.config.id,
                    value: this.value,
                    property: this.field.property
                })
            .then((response) => {
                this.preview = response.data;
            }
        );
    }
}
