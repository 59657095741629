import {computedFrom, inject} from "aurelia-framework";
import {ConditionMatcher} from "../../condition-builder/condition-matcher";
import {selectable} from "./decorators/selectable";
import {ExecEditOnItemClick} from "./mixins/exec-edit-on-item-click";

import "./cell-renderer.less";

@selectable()
@inject(
    ConditionMatcher,
    ExecEditOnItemClick
)
export class TableItemCellRenderer {

    constructor(
        conditionMatcher,
        execEditOnItemClick,
        selectService
    ) {
        this.conditionMatcher = conditionMatcher;
        this.execEditOnItemClick = execEditOnItemClick;
        this.selectService = selectService;
    }

    activate(context)
    {
        Object.assign(this, context);
        this.hasActionItems = false;

        if (this?.data?.items) {
            for (let item of this?.data?.items) {
                if (item?.actions?.length > 0) {
                    this.hasActionItems = true;
                }
            }
        }
    }

    @computedFrom('options', 'config')
    get columnCount()
    {
        let count =  this.config ? this.config.visibleColumns.length : 0;

        if (this.options.showActions) {
            count++;
        }

        if (this.config.selectable) {
            count++;
        }

        //DMS
        count++;

        return count;
    }

    keepOnlyEditActions(item, actions)
    {
        if (!actions || actions.length === 0) {
            return [];
        }

        let filtered = actions.filter(action => {
            if (!this.shouldDisplayAction(item, action)) {
                return false;
            }

            return true;
        });

        return filtered.filter(action => {
            return action.preset === 'edit' || action.preset === 'display';
        });
    }

    compactActions(item)
    {
        const actions = item.actions;


        if (!actions || actions.length === 0) {
            return [];
        }

        let filtered = actions.filter(action => {
            if (!this.shouldDisplayAction(item, action)) {
                return false;
            }

            return true;
        });

        if (filtered.length <= 2) {
            return filtered;
        }

        return filtered.filter(action => {
            return action.preset === 'edit' || action.preset === 'display';
        });
    }

    restOfActions(item)
    {
        const actions = item.actions;

        if (!actions || actions.length === 0) {
            return [];
        }

        let filtered = actions.filter(action => {
            if (!this.shouldDisplayAction(item, action)) {
                return false;
            }

            return true;
        });

        if (filtered.length <= 2) {
            return [];
        }

        let preparedActions = filtered.filter(action => {
            return action.preset !== 'edit' && action.preset !== 'display';
        });

        preparedActions = preparedActions.map(action => {
            return Object.assign({}, action, { showLabel: true });
        });

        return preparedActions;
    }

    shouldDisplayAction(item, action)
    {
        return (!action.conditions || this.conditionMatcher.matchConditions(item, action.conditions)) && true !== action.bulkOnly;
    }

    context(item, contextObjectRef)
    {
        return {id: item.id, modelId: item.modelId, contextObjectRef: contextObjectRef};
    }

    onItemDoubleClick(event, item, options)
    {
        if(this.config.selectable && this?.options?.doubleClickSubmit){
            this.selectService.select(item,true)
            this.selectService.onSelectionChange([item], true)
        }

        if (event.target && event.target.type === 'checkbox') {
            return; // Ignore double click on row selection checkbox to prevent occasional action execution
        }

        if (options && !options.showActions) {
            return;
        }

        this.execEditOnItemClick.execute(item, item.actions, this.contextObjectRef);
    }

    hasEditAction(item, options)
    {
        if (options && !options.showActions) {
            return false;
        }

        const editActions = this.keepOnlyEditActions(item, item.actions);
        return this.execEditOnItemClick.hasEditAction(editActions);
    }
}
