export const selectable = function () {
    return function (target) {
        let proto = target.prototype;

        proto.selectionClass = function (item) {
            return this.selectService.isSelected(item) ? 'selected' : '';
        };

        proto.selectAllRows = function (items) {
            if (!items || 0 == items.length) {
                return;
            }

            if (this.allItemsSelected(this.selectService.selectedItems, items)) {
                this.selectService.unselect();
            } else {
                items.forEach((item) => this.selectService.select(item, true));
            }
        };

        proto.allItemsSelected = function (selectedItems, allItems) {
            if (!selectedItems || 0 == selectedItems.length) {
                return false;
            }

            if (!allItems || 0 == allItems.length) {
                return false;
            }

            return selectedItems.length === allItems.length;
        };

        proto.selectRow = function (item) {
            let selected = this.selectService.isSelected(item);

            this.selectService.select(item, !selected);
        };
    };
};
