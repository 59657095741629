import {bindable, customElement, computedFrom} from "aurelia-framework";
import {Pagination} from "./pagination";

@customElement('sio-pagination-embedded')
export class PaginationEmbedded extends Pagination {
    @bindable offset;
    @bindable limit;
    @bindable total;

    @bindable change;
    @bindable loadWithCount;
}
