import {inject} from "aurelia-framework";
import {Client} from "../../api/client";

@inject(Client)
export class SchemaRepository {
    constructor(
        client
    ) {
        this.client = client;
    }

    get(schemaId) {
        return this.client.get('schema/' + schemaId);
    }
}