import {customElement, bindable, containerless, computedFrom} from 'aurelia-framework';

import "./template-variables-schema-field.css";

@customElement('sio-template-variables-schema-field')
@containerless()
export class TemplateVariablesSchemaField
{
    @bindable() field;
    @bindable() fieldPrefix;

    _fieldExpandingClass = 'collapsed';

    fieldPaddingLeft()
    {
        return (this.fieldPrefix.match(/\./g) || []).length * 2 + 'em';
    }

    prefixedFieldName()
    {
        if (this.field.multiple) {
            return this.fieldPrefix+this.field.name+'[]';
        } else {
            return this.fieldPrefix+this.field.name;
        }
    }

    @computedFrom('_fieldExpandingClass')
    get fieldExpandingClass()
    {
        return this._fieldExpandingClass;
    }

    @computedFrom('field')
    get hasInlineFields()
    {
        return this.field.fields && this.field.fields.length > 0;
    }

    toggleExpansion($event)
    {
        if (!this.hasInlineFields) {
            return;
        }

        if (this._fieldExpandingClass === 'expanded') {
            this.collapse();
        } else {
            this.expand();
        }
    }

    expand()
    {
        this._fieldExpandingClass = 'expanded';
    }

    collapse()
    {
        this._fieldExpandingClass = 'collapsed';
    }
}
