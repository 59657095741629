import {bindable, inject} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import * as _ from "lodash";
import {LocaleService} from "../i18n/locale-service";

import "./length-indicator.less";

@inject(LocaleService, I18N)
export class LengthIndicator {
    @bindable field;

    constructor(localeService, i18n) {
        this.i18n = i18n;
        this.localeService = localeService;
    }

    lengthText(value) {

        const length = this.extractText(value).length;
        const validationOptions = this.getLengthValidationOptions();

        if (null === validationOptions) {
            return this.getNoValidationMessage(length);
        }

        const minMessage = null == validationOptions.min ? null :
            this.i18n.tr('length-indicator.length_validation_on.limit.min', {
                "value": validationOptions.min
            })
        ;

        const maxMessage = null == validationOptions.max ? null :
            this.i18n.tr('length-indicator.length_validation_on.limit.max', {
                "value": validationOptions.max
            })
        ;

        const messages = [];

        if (minMessage) {
            messages.push(minMessage);
        }

        if (maxMessage) {
            messages.push(maxMessage);
        }

        if (0 === messages.length) {
            return this.getNoValidationMessage(length);
        }

        const limitMessage = messages.join(', ');

        return this.i18n.tr('length-indicator.length_validation_on', {
            "count": length ?? 0,
            "limit": limitMessage
        });
    }

    extractText(value) {
        if (this.field && this.field.type === 'translatable' && value?.[this.localeService.contentLocale]) {
            value = value[this.localeService.contentLocale];
        }

        if (this.field && 'htmlarea' === this.field.subType) {
            if(null != value && value.length > 0) {
                value = (value || '').replace(/(<([^>]+)>)/ig, '');
            }
        }

        return value || '';
    }

    isValid(value) {

        const length = this.extractText(value).length;
        const validationOptions = this.getLengthValidationOptions();

        if (null === validationOptions) {
            return true;
        }

        const {min, max} = validationOptions;

        if (null != min && length < min) {
            return false;
        }

        if (null != max && length > max) {
            return false;
        }

        return true;
    }

    getNoValidationMessage(count) {
        return this.i18n.tr('length-indicator.length_validation_off', {count});
    }

    getLengthValidationOptions() {
        if (!this.field || !this.field.constraints || 0 === this.field.constraints.length) {
            return null;
        }

        const lengthValidators = this.field.constraints.filter(
            validator => 'length' === validator.type ||
                'Sio\\Module\\CmsBundle\\Constraint\\OptionalLengthConstraint' === validator.type
        );

        if (0 === lengthValidators.length) {
            return null;
        }

        let min = _.get(lengthValidators[0], 'options.min', null);
        let max = _.get(lengthValidators[0], 'options.max', null);

        lengthValidators.forEach(validator => {
            const validatorMin = _.get(validator, 'options.min', null);
            const validatorMax = _.get(validator, 'options.max', null);

            if (null != validatorMin && (null == min || min < validatorMin)) {
                min = validatorMin;
            }

            if (null != validatorMax && (null == max || max > validatorMax)) {
                max = validatorMax;
            }
        });

        return {
            min,
            max
        };
    }
}
