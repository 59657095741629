/**
 * Handles renderer
 */
export class RendererService {
    /** Current active renderer */
    currentRenderer;

    onCurrentRendererChange = (currentRenderer) => {
    };

    callbacks = {
        onCurrentRendererChange: (currentRenderer) => {
        },
    };

    availableRenderer = {
        tile: {
            icon: 'fa fa-grip',
            label: 'list.tile_view',
            key: 'tile',
        },
        cell: {
            icon: 'fa fa-bars',
            label: 'list.cell_view',
            key: 'cell',
        },
        date: {
            icon: 'fa fa-calendar',
            label: 'list.calendar_view',
            key: 'calendar',
        },
        contingent: {
            icon: 'fa fa-calendar',
            label: 'Kontingent',
            key: 'contingent',
        }
    };

    renderer = [];

    /**
     * Initialize renderer with new config
     */
    initialize(config)
    {
        this.renderer = [];

        _.forIn(config.renderer, (renderer, key) => {

            if (this.availableRenderer[key]) {
                this.renderer.push(this.availableRenderer[key]);
            }
        });

        if (this.renderer.length == 0) {
            this.renderer.push(this.availableRenderer['cell']);
        }

        this.currentRenderer = this.renderer[0]['key'];
    }

    /**
     * Change active renderer
     */
    changeRenderer(key)
    {
        console.log('TEST', this.currentRenderer);
        this.currentRenderer = key;
        this.onCurrentRendererChange(this.currentRenderer);
    }
}
