import {bindable, inject, customElement} from "aurelia-framework";
import {ObserverLocator} from "aurelia-binding";
import {I18N} from 'aurelia-i18n';

import "./codemirror.less";

import CodeMirror from "codemirror/lib/codemirror.js";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/twig/twig";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/addon/mode/overlay";

@inject(
    I18N
)
@customElement('sio-codemirror')
export class HtmlArea
{
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value;

    editorInstance = null;
    editorNode;

    viewChange = false;

    constructor(
        i18n
    ) {
        this.i18n = i18n;
    }

    valueChanged()
    {
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this.editorInstance && this.editorInstance.setValue(this.value);
    }

    attached()
    {
        CodeMirror.defineMode("htmltwig", function(config, parserConfig) {
            return CodeMirror.overlayMode(
                CodeMirror.getMode(config, "htmlmixed"),
                CodeMirror.getMode(config, "twig")
            );
        });

        this.editorInstance = CodeMirror(this.editorNode, {
            value: this.field.value || '',
            mode: this.field.options?.mode || "htmltwig",
            lineNumbers: true,
            lineWrapping: true
        });
        this.editorInstance.setSize(null, 500);

        this.editorInstance.on("changes", () => {
            this.viewChange = true;

            const content = this.editorInstance.getValue();

            if (content !== '') {
                this.field.value = content;
            } else {
                this.field.value = null;
            }
        });
    }
}
