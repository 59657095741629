import {customElement, bindable, inject, LogManager} from 'aurelia-framework';
import {SchemaRepository} from "./repository/schema-repository";
import {EventAggregator} from 'aurelia-event-aggregator';

import "./template-variables-helper-panel.css";

const logger = LogManager.getLogger('template-variables-schema');

@customElement('sio-template-variables-helper-panel')
@inject(SchemaRepository, EventAggregator)
export class TemplateVariablesHelperPanel
{
    @bindable() schemaChoiceField;

    formValueChangedSubscription = null;

    schema = null;

    constructor(
        schemaRepository,
        ea
    ) {
        this.schemaRepository = schemaRepository;
        this.ea = ea;
    }

    detached() {
        if (this.formValueChangedSubscription) {
            this.formValueChangedSubscription.dispose();
        }
    }

    schemaChoiceFieldChanged()
    {
        if (this.formValueChangedSubscription) {
            this.formValueChangedSubscription.dispose();
            this.formValueChangedSubscription = null;
        }

        if (!this.schemaChoiceField) {
            this.schema = null;

            return;
        }

        this.formValueChangedSubscription = this.ea.subscribe(
            'sio_form_value_changed', this.onSchemaChoiceFieldValueChanged.bind(this)
        );

        this.onSchemaChoiceFieldValueChanged({
            field: this.schemaChoiceField
        });
    }

    /**
     * This callback is executed when value of some form in application is updated.
     *
     * @param event {Object} Object that contains two fields:
     *                       - form : object of Form class that changed value
     *                       - field : object of FormField class that changed value and belongs to specified form
     */
    onSchemaChoiceFieldValueChanged(event)
    {
        if (!this.schemaChoiceField || event.field !== this.schemaChoiceField) {
            return;
        }

        let schemaId = this.schemaChoiceField.value;

        if (schemaId) {
            this.schemaRepository.get(schemaId).then(
                (schema) =>
                {
                    logger.debug('FETCHED TEMPLATE VARIABLES SCHEMA', schema);

                    this.schema = schema;
                }
            );
        } else {
            this.schema = null;
        }
    }
}
