import {customElement, bindable, inject} from "aurelia-framework";
import {Client} from '../api/client';
import {EventAggregator} from 'aurelia-event-aggregator';
import {FlashService} from "../flash/flash-service";

import "./time-tracking.less";
import {StandardActions} from "../action/standard-actions";

@customElement('sio-time-tracking-widget')
@inject(Client, EventAggregator, FlashService, StandardActions)
export class TimeTracking
{
    @bindable user;
    loading = false;

    constructor(client, ea, flash, actions) {
        this.client = client;
        this.ea = ea;
        this.flash = flash;
        this.actions = actions;
    }

    startTracking()
    {
        if (this.loading) {
            return;
        }

        this.loading = true;

        return this.client.post('human-resources/start-user-tracking').then(data => {

            this.loading = false;

            this.ea.publish(
                'sio_form_post_submit',
                {config: {modelId: 'user/user'}}
            );

            return data;
        }, error => {
            this.loading = false;

            let data = error.data;

            if(data && data.localizedMessage) {

                this.flash.error(data.localizedMessage);
            }

            return error;
        });
    }

    stopTracking()
    {
        if (this.loading) {
            return;
        }

        this.loading = true;

        return this.client.post('human-resources/stop-user-tracking').then(data => {

            this.loading = false;

            this.ea.publish(
                'sio_form_post_submit',
                {config: {modelId: 'user/user'}}
            );

            if (!data?.data?.id) {
                return data;
            }

            return this.actions.getAction({
                type: 'view',
                viewId: 'hr-time-tracking/time-log-manage',
                modal: true,
            }, {
                id: data.data.id,
            }).action();

        }, error => {
            this.loading = false;

            let data = error.data;

            if(data && data.localizedMessage) {

                this.flash.error(data.localizedMessage);
            }

            return error;
        });
    }

}
