import {ViewSettings} from "../view-settings/view-settings";
import {inject, LogManager} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {UserClient} from "../../api/user-client";
import {BindFormValuesService} from '../../form/loader/bind-form-values.service';
import {FlashService} from '../../flash/flash-service';

const logger = LogManager.getLogger('sio-table:ViewSettingsService');

@inject(DialogService, UserClient, FlashService)
export class ViewSettingsService {
    view;
    config;

    onViewChange = () => {
    };

    constructor(
        dialogService,
        userClient,
        flash
    ) {
        this.dialogService = dialogService;
        this.userClient = userClient;
        this.flash = flash;
    }

    open() {
        return this.dialogService.open({
            viewModel: ViewSettings,
            model: {
                config: this.config,
                modelId: this.config.modelId,
                currentView: this.view,
                viewContext: this.viewContext,
                onViewChange: this.onViewChange,
            }
        });
    }

    async initialize(config, viewContext) {
        this.config = config;
        this.viewContext = viewContext;

        if (!config.activeView) {
            config.visibleColumns = config.columns;
            return config;
        }

        this.view = config.activeView.id;
        //Todo load settings

        let view = config.activeView;
        let viewColumns = view.columns;
        let visibleColumns = _.cloneDeep(config.columns);

        _.remove(visibleColumns, (column) => {
            return viewColumns.indexOf(column.property) == -1;
        });

        visibleColumns = _.sortBy(visibleColumns, [(column) => {
            return viewColumns.indexOf(column.property);
        }]);

        config.visibleColumns = visibleColumns;
        if (view.sort) {
            config.defaultSort = view.sort;
        }

        logger.debug('Calculated visible columns', visibleColumns);
    }

    /*private*/ async _buildBindingContext(config, params)
    {
        return {
            '@config': config, // CalculationTable configuration
            '@user': this.userClient.user // Current user
        };
    }
}
