import {customElement, bindable, inject, LogManager} from "aurelia-framework";
import {BulkOperationsRepository} from "../bulk-operations/bulk-operations-repository";
import {ActionsGenerator} from "../bulk-operations/actions-generator";

import "./bulk-operations-display.less";

const logger = LogManager.getLogger("BulkOperationsDisplay");

@customElement('sio-bulk-operations-display')
@inject(
    BulkOperationsRepository,
    ActionsGenerator
)
export class BulkOperationsDisplay
{
    @bindable operations;
    @bindable context;

    actions;

    constructor(bulkOperationsRepository, actionsGenerator)
    {
        this.bulkOperationsRepository = bulkOperationsRepository;
        this.actionsGenerator = actionsGenerator;
    }

    bind()
    {
        this.updateAvailableActions();
    }

    contextChanged()
    {
        logger.debug("Context binding changed", this);

        this.updateAvailableActions();
    }

    operationsChanged()
    {
        logger.debug("Operations binding changed", this);

        this.updateAvailableActions();
    }

    /*private*/ updateAvailableActions()
    {
        this.actions = this.prepareActions(this.context.items, this.operations);
    }

    /*private*/ prepareActions(items, operations)
    {
        if (!items || items.length === 0) {
            return [];
        }

        let actions = [];

        operations.forEach(operationConfig => {
            const operation = this.bulkOperationsRepository.getOperation(operationConfig.type);

            actions = actions.concat(
                this.actionsGenerator.generateActions(items, operationConfig)
            );
        });

        return actions;
    }
}
