import {inject, view} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {StandardActions} from '../../action/standard-actions';
import {Client} from "../../api/client";
import {UserClient} from "../../api/user-client";
import * as _ from 'lodash';
import {DialogService} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';

@inject(DialogController, Client, UserClient, DialogService, EventAggregator, StandardActions)
export class ViewSettings {
    onViewChange;

    currentFilters = [];

    constructor(controller, client, userClient, dialogService, ea, standardActions) {
        this.controller = controller;
        this.client = client;
        this.userClient = userClient;
        this.dialogService = dialogService;
        this.ea = ea;
        this.standardActions = standardActions;
    }

    attached() {
        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {

            if (response.config.modelId != 'configuration/list-view') {
                return;
            }

            this._load().then(() => {

            });
        });
    }

    detached() {
        this.subscription.dispose();
    }

    async activate(context) {
        this.config = context.config;
        this.modelId = context.modelId;
        this.currentView = context.currentView;
        this.currentFilters = context.config?.activeView?.enabledFilters ?? [];
        this.viewContext = context.viewContext;
        this.onViewChange = context.onViewChange;

        await this._load();
    }

    async _load() {
        this.views = [];

        let user = this.userClient.user;

        let viewContextConditions;
        if (this.viewContext) {
            viewContextConditions = {
                $or: [
                    {
                        viewContext: {
                            '$exists': false
                        }
                    },
                    {
                        viewContext: this.viewContext
                    }
                ]
            };
        } else {
            viewContextConditions = {viewContext: {'$exists': false}};
        }

        let conditions = {
            $and: [
                {modelIdentifier: this.modelId,},
                {hidden: {'$ne': true}},
                viewContextConditions
            ]
        };

        let response = await this.client.get('configuration/list-view?conditions=' + JSON.stringify(conditions) + '&embeds[]=createdBy');

        this.views = _.orderBy(response.items, ['createdBy', 'title'], ['desc', 'asc']);

        console.log('views', this.views);
    }

    save() {

        return this.client.post(
            'configuration/list-view-setting',
            {
            modelId: this.modelId,
            viewId: this.currentView,
            viewContext: this.viewContext,
            enabledFilters: this.currentFilters
        }).then(response => {

            this.controller.ok(this.currentView);

            this.onViewChange();
        });
    }

    delete(context) {
        return this.standardActions.getAction({type: 'delete'}, context).action().then(async () => {
            // Find which view is active now and set as current view in editor

            this.client.removeCache(this.modelId + '/list');
            const listConfig = await this.client.get(`${this.modelId}/list?viewContext=${this.viewContext}`);

            this.currentView = (listConfig.activeView && listConfig.activeView.id) || null;

            this.onViewChange();
        });
    }
}
