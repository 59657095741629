import {inject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {UniversalEntitySelect} from '../../dialog/universal-entity-select';
import {Client} from '../../api/client';
import {FlashService} from '../../flash/flash-service';
import {I18N} from 'aurelia-i18n';
import * as _ from 'lodash';

@inject(DialogService, Client, FlashService, I18N)
export class TextTemplateSelect
{
    constructor(dialogService, client, flash, i18n)
    {
        this.dialogService = dialogService;
        this.client = client;
        this.flash = flash;
        this.i18n = i18n;
    }

    initialize(tinymce)
    {
        //This gets overwritten in callback so declaring locally
        let dialogService = this.dialogService;
        let client = this.client;
        let flash = this.flash;
        let i18n = this.i18n;

        tinymce.PluginManager.add('texttemplateselect', function(editor, url) {
            // Add a button that opens a window
            editor.addButton('texttemplateselect', {
                tooltip: i18n.tr('base.text-template.select'),
                icon: ' overwrite-fa-font fa fa-file-lines',
                onclick: () => {

                    dialogService.open(
                        {
                            viewModel: UniversalEntitySelect,
                            model: {selectModelId: 'base/text-template'}
                        }
                    ).whenClosed(data => {

                        let items = data.output;

                        if (!items || items.length === 0) {
                            return Promise.resolve(null);
                        }

                        let ids = _.map(items, (e) => {
                            return e.id;
                        });

                        return client.get('base/text-template?conditions=' + JSON.stringify({id: {$in: ids}})).then(
                            response => {
                                _.each(response.items, (item) => {
                                    editor.insertContent(item.content);
                                });
                            }
                        ).catch(error => {
                            flash.error(i18n.tr('base.text-template.error-html-editor-select'));
                        });
                    });
                }
            });
        });
    }

}
