import {bindable, customElement, inject} from "aurelia-framework";
import {ObserverLocator} from "aurelia-binding";
import {I18N} from 'aurelia-i18n';

import "./htmlarea.less";

import "tinymce";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/paste";
import "tinymce/plugins/preview";
import "tinymce/plugins/print";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/visualblocks";
import "tinymce/themes/modern";
import {MediaDatabaseSelect} from './plugin/media-database-select';
import {TextTemplateSelect} from './plugin/text-template-select';
import {ArtificialIntelligence} from "../artificial-intelligence/helper/artificial-intelligence";
import {FlashService} from "../flash/flash-service";
import {Translatable} from "./translatable";
import {ArtificialIntelligencePlugin} from "../artificial-intelligence/plugin/artificial-intelligence-plugin";

@inject(
    I18N,
    ObserverLocator,
    MediaDatabaseSelect,
    TextTemplateSelect,
    ArtificialIntelligence,
    ArtificialIntelligencePlugin,
    FlashService,
    Translatable,
)
@customElement('sio-html-area')
export class HtmlArea {
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value;

    editorInstance = null;
    editorNode;

    viewChange = false;

    constructor(
        i18n,
        observerLocator,
        mediaDatabaseSelect,
        textTemplateSelect,
        artificialIntelligence,
        artificialIntelligencePlugin,
        flashService,
        translatable,
    ) {
        this.i18n = i18n;
        this.mediaDatabaseSelect = mediaDatabaseSelect;
        this.textTemplateSelect = textTemplateSelect;
        this.artificialIntelligence = artificialIntelligence;
        this.artificialIntelligencePlugin = artificialIntelligencePlugin;
        this.flash = flashService;
        this.translatable = translatable;
    }

    valueChanged() {
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        if (this.editorInstance) {
            this.editorInstance.setContent(this.value || '');
        }
    }

    async openArtificialIntelligenceDialog(context) {
        const {id, modelId} = context?.parent?.contextObjectRef ?? {};

        try {
            const res = await this.artificialIntelligence.generate({id: id, modelId: modelId});

            if (this.artificialIntelligence.validateResponseObj(res)) {
                return;
            }

            this.value = res.response;
            this.valueChanged();
        } catch (error) {
            console.error(error);
            this.flash.error(error?.localizedMessage ?? error?.message ?? error ?? "Error");
        }
    }

    attached() {
        console.log('attached again', 'blupp');

        // TODO: Implement multilangual support
        // Right now TinyMCE crashes with https://github.com/tinymce/tinymce/issues/2655 error when we specify language_url.

        let language = window.localStorage.i18nextLng ?? "de"; //this.i18n.i18next.language;

        this.mediaDatabaseSelect.initialize(tinymce);
        this.textTemplateSelect.initialize(tinymce);
        this.artificialIntelligencePlugin.initialize(tinymce, this.field);

        let config = {
            target: this.editorNode,
            plugins: [
                'advlist autolink lists link image charmap print preview textcolor anchor ' + (this.field.fullPage ? 'fullpage' : ''),
                'searchreplace visualblocks code fullscreen pagebreak mediadatabaseselect texttemplateselect artificialintelligencegenerate',
                'insertdatetime media table contextmenu paste'
            ],
            protect: [
                /{%(.*)%}/g, // Allow TWIG control codes
                /{#(.*)#}/g, // Allow TWIG comment codes
            ],
            image_caption: true,
            browser_spellcheck: true,
            menubar: false,
            height: this.field.options.height ? this.field.options.height : 500,
            toolbar: 'undo redo | insert pastetext | styleselect fontsizeselect forecolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image mediadatabaseselect texttemplateselect artificialintelligencegenerate | code',
            //content_css: '//www.tinymce.com/css/codepen.min.css',
            skin_url: '/tinymce/skins/lightgray',

            fullpage_default_encoding: 'UTF-8',
            entity_encoding: 'raw',
            extended_valid_elements: 'script[language|type|src]', // allow customers to insert widgets

            language: language,
            language_url: language !== 'en' && '/tinymce/langs/' + language + '.js',

            setup: editor => {
                editor.on('init', e => {
                    this.editorInstance = editor;
                    editor.setContent(this.value || '');
                });
                editor.on('change redo undo keydown', e => {
                    this.viewChange = true;

                    const content = editor.getContent();
                    this.value = '' !== content ? content : null;
                });
            }
        };

        if (this.field.fullPage) {
            config['force_br_newlines'] = false;
            config['force_p_newlines'] = false;
            config['forced_root_block'] = '';
        }

        tinymce.init(config);
    }
}
