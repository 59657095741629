import {inject} from "aurelia-framework";
import * as _ from "lodash";
import {ActionConfigPreparation} from "../../../action/action-config-preparation";
import {StandardActions} from "../../../action/standard-actions";

@inject(
    StandardActions
)
export class ExecEditOnItemClick {
    constructor(standardActions)
    {
        this.standardActions = standardActions;
    }

    execute(item, actions, contextObjectRef)
    {
        if (!actions || actions.length === 0) {
            return;
        }

        const editActions = actions.filter(action => {
            return action.preset === 'edit' || action.preset === 'display';
        });

        if (_.isEmpty(editActions)) {
            return;
        }

        let editAction = editActions[0]; // Use first of edit actions for double click

        editAction = ActionConfigPreparation.prepare(editAction);
        const {action, context} = this.standardActions.getAction(
            editAction,
            {
                id: item.id,
                modelId: item.modelId,
                contextObjectRef: contextObjectRef
            }
        );

        action();
    }

    hasEditAction(actions)
    {
        if (!actions || actions.length === 0) {
            return false;
        }

        const editActions = actions.filter(action => {
            return action.preset === 'edit' || action.preset === 'display';
        });

        return !_.isEmpty(editActions);
    }
}
