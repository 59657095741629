import {inject} from 'aurelia-framework';
import {Client} from "../../api/client";
import $ from "jquery";
import * as _ from 'lodash';

@inject(Client)
export class MongoDataLoader
{
    constructor(client)
    {
        this.client = client;
    }

    getData(modelId, request)
    {
        request = _.cloneDeep(request);
        request.conditions = JSON.stringify(request.conditions);

        //Note do not want to modify request here
        return this.client.get(modelId + '?' + $.param(request));
    }
}
