import $ from 'jquery';
import 'fullcalendar';
import 'fullcalendar/dist/locale-all';
import {customElement, bindable, inject} from 'aurelia-framework';
import {ConditionMatcher} from "../../condition-builder/condition-matcher";
import * as _ from "lodash";
import moment from "moment";
import {observable} from "aurelia-binding";
import {StandardActions} from "../../action/standard-actions";

@customElement('sio-calendar')
@inject(
    ConditionMatcher,
    StandardActions
)
export class Calendar {

    @bindable departments;

    @bindable showDepartments;

    @observable data;
    calendar;

    choiceConfig = {
        modelId: 'user/department',
        multiple: true,
    };

    constructor(conditionMatcher, actions) {
        this.conditionMatcher = conditionMatcher;
        this.actions = actions;
    }

    attached() {
        $(this.calendar).fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek'
            },
            defaultView: this.config.defaultView ? this.config.defaultView : 'month',
            locale: 'de',
            eventSources: [
                this.loadEvents.bind(this)
            ],
            eventRender: function (event, element) {
                $(element).tooltip({title: event.title, container: 'body'});
            },
            eventClick: function (ev) {
                if (ev.action) {
                    ev.action.action();
                }

            },
            timezone: 'local'
        });

    }

    activate(context) {
        Object.assign(this, context);
        console.log(this);
    }

    reload() {
        $(this.calendar).fullCalendar('refetchEvents');
    }

    loadEvents(startDate, endDate, timezone, callback) {

        let conditions = [];

        this.showDepartments = this.config.modelId === 'human-resources/availability';

        if (typeof (this.config.renderer.date.startDate) != "undefined" && typeof (this.config.renderer.date.endDate) != "undefined") {
            conditions.push({[this.config.renderer.date.endDate]: {'$gte': startDate.format('YYYY-MM-DD')}})
            conditions.push({[this.config.renderer.date.startDate]: {'$lte': endDate.format('YYYY-MM-DD')}})
        } else if (typeof (this.config.renderer.date.date) != "undefined") {
            conditions.push({
                [this.config.renderer.date.endDate]: {
                    '$gte': startDate.format('YYYY-MM-DD'),
                    '$lte': endDate.format('YYYY-MM-DD'),
                }
            })
        }

        if (this.contextObjectRef && this.contextObjectRef.modelId == 'user/user') {
            conditions.push({user: {'$eq': this.contextObjectRef}});
            conditions.users = [this.user];
        } else if (this.departments) {
            conditions.push({'departments': {'$in': this.departments}})
            conditions.users = this.departments;
        }

        let request = {
            conditions: {
                '$and': conditions
            },
            embeds: this.config.embeds ? this.config.embeds : [],
            hrCalc: !!this.showDepartments
        };

        this.tableService.setFilters(request.conditions, true);

        callback([]);

    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async waitFor(test, timeout_ms = 20 * 1000) {
        return new Promise(async (resolve, reject) => {
            if (typeof (timeout_ms) != "number") reject("Timeout is not a number");
            var freq = 100;
            var result
            while (result === undefined || result === false || result === null || result.length === 0) {
                if ((timeout_ms -= freq) < 0) {
                    resolve(false);
                    return;
                }
                await this.sleep(freq);
                result = typeof (test) === 'string' ? eval(test) : test();
            }
            resolve(result);
        });
    }


    refactorResult(items) {

        if (typeof this.config === 'undefined') {
            return [];
        }

        let label = (this.config.renderer.label) ? this.config.renderer.label : 'title';

        let modified = [];
        _.each(items, result => {

            let endDate = result[this.config.renderer.date.endDate];

            if (endDate !== result[this.config.renderer.date.startDate] && result.allDay) {
                endDate = moment(result.endDate).add(1, 'days').format('YYYY-MM-DD');
            }
            let action = null;
            if (result.id && result.modelId) {
                let context = {
                    id: result.id,
                    modelId: result.modelId,
                };

                action = this.actions.getAction({
                    type: 'view',
                    icon: 'fa fa-pencil',
                    buttonClass: 'btn btn-danger btn-xs',
                    viewId: 'human-resources/hr-event-manage',
                    bulk: false,
                    modal: true
                }, context)
            }

            modified.push({
                start: result[this.config.renderer.date.startDate],
                end: endDate,
                allDay: result.allDay,
                title: result.name ? result.name : (label && result[label]) ? result[label] : result['title'],
                color: result.color,
                action: action
            })


        });

        return modified;
    }

    dataChanged(newData, oldData) {
        if (newData !== oldData && newData !== null) {
            let events = $(this.calendar).fullCalendar('getEventSources');
            if (typeof events[1] !== 'undefined') {
                $(this.calendar).fullCalendar('removeEventSources', events[1]);
            }
            $(this.calendar).fullCalendar('addEventSource', this.refactorResult(newData.items));
        }
    }

    departmentsChanged() {
        this.reload();
    }

}
